import { createSelector } from 'reselect';

const getProducts = (state) => state.basket.product;
const filterProduct = (product, id) => {
  return product.filter((item) => item.id === id);
};

export const getProduct = createSelector(
  getProducts,
  (_, id) => id,
  filterProduct
);
