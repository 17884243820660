import React, { useEffect, useMemo, useState } from 'react'
import request from '../../helpers/request';

import styles from './coupon-code.module.scss'

export default function CouponCode(props) {
  const [isValid, setIsValid] = useState(0)
  const [code, setCode] = useState('')
  const [dbCode, setDbCode] = useState(null)
  useEffect(() => {
    if (!code) return
    request(true).get(`/coupons/validate?code=${code}`).then(e => {
      if (e.status === 200) {setIsValid(1);setDbCode(e.data)}
    }).catch(() => {
      setIsValid(-1)
    })
  }, [code])
  const onChange = (e) => {
    setCode(e.target.value)
    props.onChange(e.target.value)
  }
  const validFor = useMemo(() => {
    if (!dbCode) return ''
    const dca = dbCode?.discount_amount
    const type = dca?.type
    const amount = dca?.type === 'Fixed' ? dca?.amount / 100 : dca?.amount * 100
    let s = `This code is for ${type === 'Fixed' ? '£' : ''}${amount}${type === 'Percent' ? '%' : ''} off when you spend at least £${dbCode?.minimum_value / 100}`
    return s
  }, [dbCode])
  return [
    <div className={styles.container}>
      <input className={styles.input} type="text" placeholder="Discount code (Optional)" onChange={onChange} />
      { isValid === 1 ? <div key="isValid1"><span title="Coupon code is valid" style={{color: 'green'}} className="fas fa-check"></span></div> : null }
      {/* { isValid === 0 ? <div key="isValid0"><span title="Enter a coupon code for a discount" className="fas fa-question"></span></div> : null } */}
      { isValid === -1 ? <div key="isValid-1"><span title="Coupon code is invalid" style={{color: 'red'}} className="fas fa-times"></span></div> : null }
    </div>,
    <div className={styles.messages}>
      { isValid === 1 ? <p>{validFor}</p> : null }
      { isValid === -1 ? <p>Coupon code is not valid.</p> : null }
    </div>
  ]
}
