import { view } from '@risingstack/react-easy-state';
import React from 'react'
import basket from '../../stores/basket';

import './header-basket.scss';

export default view(function HeaderBasket() {
  return (
    <div className="header-basket">
      {
        basket.items.length > 0 ? 
        <div className="pulse">
          <div className="circle"></div>
        </div> : null
      }
      <span className="fa-layers fa-fw">
        <i className="far fa-shopping-cart"></i>
        {
          basket.items.length > 0 ? <span className="fa-layers-counter"></span> : null
        }
      </span>
      <span className="title">Basket</span>
    </div>
  )
})
