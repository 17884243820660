import React from 'react';
import './page.scss';

import Header from '../header/header';
import Footer from '../footer/footer';
import StickyViewBasket from '../sticky-view-basket/sticky-view-basket';
import { withRouter } from 'react-router-dom';    

function Page({ children, ...props }) {
    const { location } = props;
    const isBasket = location.pathname.includes("basket")
    return (
        <div id="page-main">
            <Header />
            <main>
                {children}
                {!isBasket && (
                    <StickyViewBasket />
                )}
            </main>
            <Footer isBasket={isBasket} />
        </div>
    )
}

export default withRouter(Page);
