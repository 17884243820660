import React, { useCallback, useState } from 'react'
import ReactModal from 'react-modal'
import Request from '../../../helpers/request'
import Style from '../BasicDetails/BasicDetails.module.scss'

export const AddressListContainer = ({self, getData}) => {
    return (
        self.addresses && self.addresses[0] ? <>
            <div>
                {
                    self.addresses.map(address => <AddressContainer key={`user-address-${address._id}`} getData={getData} address={address} />)
                }
            </div>
        </> : <div>
            <h3>Something is wrong with your account, contact support.</h3>
        </div>
    )
}

export const AddressContainer = ({address, getData}) => {
    const [showModal, setShowModal] = useState(false)
    const [newAddress, setNewAddress] = useState({
        ...address,
        _id: undefined
    })

    const update = useCallback(() => {
        Request(true).patch(`/self/addresses/${address._id}`, newAddress).then(() => {
            getData()
            window.alert('Address updated!');
            setShowModal(false)
        }).catch(err => {
            window.alert('There was an issue updating your address, check all fields are filled and try again.')
        })
    }, [address, getData, newAddress])

    const handleChange = useCallback((e) => {
        setNewAddress({
            ...newAddress,
            [e.target.name]: e.target.value
        })
    }, [newAddress])

    return [
        <div style={{display: 'grid',gridTemplateColumns: 'auto min-content'}}>
            <div style={{fontSize: 20, fontWeight: 600}}>
                { address.buildingName ? `${address.buildingName}, ` : null }
                {address.street}{', '}<br />
                {address.country}{', '}
                {address.town}{', '}
                {address.postcode}
            </div>
            <div>
                <button className="button button-view-basket button-green" onClick={() => setShowModal(true)}>Update</button>
            </div>
        </div>,
        <ReactModal overlayClassName={Style['custom-height']} isOpen={showModal} onRequestClose={() => setShowModal(false)}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', cursor: 'pointer'}} onClick={() => setShowModal(false)}>
                <span className="fas fa-times fa-lg"></span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    <div className={Style['col-2']}>
                        <input className="color-mint-green-outline color-grey" style={{padding: 15}} type="text" defaultValue={address.buildingName} name="buildingName" placeholder="Building Name / House Number" onChange={handleChange} />
                        <input className="color-mint-green-outline color-grey" style={{padding: 15}} type="text" defaultValue={address.street} name="street" placeholder="Street" onChange={handleChange} />
                    </div>
                    <div className={Style['col-2']}>
                        <input className="color-mint-green-outline color-grey" style={{padding: 15}} type="text" defaultValue={address.town} name="town" placeholder="Town" onChange={handleChange} />
                        <input className="color-mint-green-outline color-grey" style={{padding: 15}} type="text" defaultValue={address.country} name="country" placeholder="Country" onChange={handleChange} />
                    </div>
                    <div className={Style['col-2']}>
                        <input className="color-mint-green-outline color-grey" style={{padding: 15}} type="text" defaultValue={address.postcode} name="postcode" placeholder="Postcode" onChange={handleChange} />
                        <input className="color-mint-green-outline color-grey" style={{padding: 15}} type="text" defaultValue={address.phoneNumber} name="phoneNumber" placeholder="Phone Number" onChange={handleChange} />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 7}}>
                    <button className="button button-solid button-view-basket" onClick={() => setShowModal(false)}>Close</button>
                    <button className="button button-green-solid button-view-basket" onClick={() => update()}>Update address</button>
                </div>
            </div>
        </ReactModal>
    ]
}