import { store } from '@risingstack/react-easy-state';
import request from '../helpers/request';
import { store as toast } from 'react-notifications-component';
import moment from 'moment'

const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
// const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
// const timeSlots = ["Early Morning", "Morning", "Afternoon", "Evening"]

const BasketState = store({
  address: null,
  paymentMethod: null,
  items: [],
  lastBasketPrice: null,
  productInBasket: (product) => {
    return (
      BasketState.items.findIndex((a) => a.raw.product._id === product._id) !==
      -1
    );
  },
  variantInBasket: (variant) => {
    return (
      BasketState.items.findIndex((a) => a.raw.variant._id === variant._id) !==
      -1
    );
  },
  addItemToBasket: (product, variant, customisations, quick) => {
    if (BasketState.variantInBasket(variant)) {
      toast.addNotification({
        title: 'Oops',
        message: 'You can only add a product once',
        type: 'info',
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return false;
    }
    BasketState.items.push({
      raw: {
        product,
        variant,
      },
      id: product._id,
      customisations,
      quickDelivery: quick,
      frequency: 'Once',
      quantity: 1,
      slots: [
        {
          day: null,
          slot: null,
        },
      ],
    });

    // console.log(BasketState);

    localStorage.setItem('localo-basket', JSON.stringify(BasketState));
    BasketState.getBasketPrice();
    localStorage.setItem('localo-basket-last-updated', new Date().toISOString())
    return true;
  },
  setItems: (items) => {
    BasketState.items = items;
    localStorage.setItem('localo-basket', JSON.stringify(BasketState));
    BasketState.getBasketPrice();
  },
  getBasketPrice: () => {
    request(false)
      .post('/helpers/price-cart', {
        items: BasketState.parseToCartFormat(false),
      })
      .then((e) => {
        BasketState.lastBasketPrice = e.data;
      });
  },
  parseToCartFormat: (useIds = true) => {
    const cart = [];
    // console.log('To Cart Format', BasketState.items)
    BasketState.items.forEach((item) => {
      // console.log('parsing', item.raw.variant.title, item.slots)
      if (item.slots) {
        item.slots.forEach((slot) => {
          // console.log('Slots', slot)
          cart.push({
            product: useIds ? item.raw.product._id : item.raw.product,
            variant: useIds ? item.raw.variant._id : item.raw.variant,

            options: item.customisations.map((cus) => {
              // console.log('BASKET OPTIONS', cus)
              // console.log('BASKET OPTIONS', item.raw.variant.customisation.find(_ => _._id === cus.option))
              // console.log('BASKET OPTIONS', item.raw.variant.customisation.find(_ => _._id === cus.option).options.find(_ => _._id === cus.value))
              return {
                option: item?.raw?.variant?.customisation?.find(
                  (_) => _._id === cus.option
                ),
                value: item?.raw?.variant?.customisation?.find((_) => _._id === cus.option)?.options?.find((_) => _._id === cus.value),
              };
            }),

            repeatType: item.frequency,
            repeatWeek: weeks[slot.week || 0],
            quantity: slot.quantity,

            deliveryDay: slot.day,
            deliveryTime: slot.slot,

            quickDelivery: item.quickDelivery,
          });
        });
      }
    });
    // console.log('CART', cart)
    return cart;
  },
  addAllToCart: () => {},
  attemptPurchase: () => {},
  getStateFromLS: () => {
    let expiryLs = localStorage.getItem('localo-basket-last-updated')
    if (!expiryLs) return localStorage.removeItem('localo-basket')
    let expiry = moment().diff(expiryLs, 'days')
    if (expiry > 3) {
      localStorage.removeItem('localo-basket-last-updated')
      return localStorage.removeItem('localo-basket')
    }
    let basket = localStorage.getItem('localo-basket');

    if (basket) {
      basket = JSON.parse(basket);
      Object.keys(basket).forEach((k) => (BasketState[k] = basket[k]));
    }
  },
});

BasketState.getStateFromLS();
BasketState.parseToCartFormat();
BasketState.getBasketPrice();

export default BasketState;
