import { actionType } from './reducer';
// future use
// import store from '../../../redux/store';

export const setDeliverySlots = (slots, productId) => {
  return {
    type: actionType.SET_DELIVERY_SLOT,
    payload: { slots, id: productId },
  };
};

export const setCost = (cost, productId) => {
  return {
    type: actionType.SET_PRODUCT_COST,
    payload: { cost, id: productId },
  };
};

export const setVendor = (vendor, productId) => {
  return {
    type: actionType.SET_PRODUCT_VENDER,
    payload: { vendor, id: productId },
  };
};

export const removeProduct = (id) => {
  return {
    type: actionType.REMOVE_PRODUCT,
    payload: { id },
  };
};

export const setIsLoading = (flag) => {
  return {
    type: actionType.IS_LOADING,
    flag,
  };
};
