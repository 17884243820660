import { view } from '@risingstack/react-easy-state';
import React from 'react';
import Request from '../../helpers/request';
import Session from '../../stores/session';
import Container from '../container/container';
import './login-register.scss';
import { store } from 'react-notifications-component';
// import StickyLogin from './sticky-login';

export default view(function Register ({ setLoginOrRegister, redirectUri, fromBasket, shouldNotRedirect, ...props })
{
    const [formState, setFormState] = React.useState({
        name: '',
        housenumber: '',
        street: '',
        town: '',
        postcode: '',
        country: '',
        telephone: '',
        email: '',
        password: '',
        'repeat-password': ''
    });

    const [newsletter, setNewsletter] = React.useState(false);
    const [error, setError] = React.useState('');
    const [message, setMessage] = React.useState('');

    const onChange = React.useCallback(ev => {
        setFormState({
            ...formState,
            [ev.target.name]: ev.target.value
        });
    }, [formState, setFormState]);

    const newsletterChange = React.useCallback(ev => {
        setNewsletter(ev.target.checked);
    }, [])

    const registerRequest = React.useCallback(ev => {
        ev.preventDefault();

        setMessage('');
        setError('');

        if (formState.password !== formState["repeat-password"]) {
            setError('Passwords do not match.');
            setFormState({
                ...formState,
                password: '',
                "repeat-password": '',
            });

            return;
        }

        Request(true).post('/auth/register', {
            ...formState,
            newsletter: newsletter
        }).then(r => {
            // console.log(r);
            if (r.status === 201) {
                store.addNotification({
                    title: "Hooray!",
                    message: 'Account created, you are now logged in!',
                    type: "success",
                    insert: "bottom",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                localStorage.setItem('user-token', r.data.token)
                Session.token = r.data.token
                Session.fetch()
                if(!shouldNotRedirect)
                props.history.push(redirectUri || '/')
            }
        }).catch(e => {
            // console.log(e);
            // console.log(e.response);

            setFormState({
                ...formState,
                password: '',
                "repeat-password": ''
            });

            if (e.response) {
                if (e.response.data.error === "USER_ALREADY_EXISTS") {
                    setError('A user already exists with this email address');
                    return;
                }

                setError(e.response.data);
            }
        });
    }, [formState, newsletter, props.history, shouldNotRedirect, redirectUri]);

    return (
        <Container className='container-register'>
            <div className="login-or-register">
                {!fromBasket ? (<h1>Register</h1>) : ''}
                <form onSubmit={registerRequest}>
                    <input onChange={onChange} value={formState.forename} type="text" name="name" placeholder="Name"/>
                    <div className="address">
                        <input onChange={onChange} value={formState.housenumber} type="number" name="housenumber" placeholder="#"/>
                        <input onChange={onChange} value={formState.street} type="text" name="street" placeholder="Street name"/>
                    </div>
                    <div className="grid-2-wide">
                        <input onChange={onChange} value={formState.town} type="text" name="town" placeholder="Town"/>
                        <input onChange={onChange} value={formState.postcode} type="text" name="postcode" placeholder="Postcode"/>
                    </div>
                    <div className="grid-2-wide">
                        <input onChange={onChange} value={formState.country} type="text" name="country" placeholder="Country"/>
                        <input onChange={onChange} value={formState.telephone} type="text" name="telephone" placeholder="Telephone"/>
                    </div>
                    <input onChange={onChange} value={formState.email} type="email" name="email" placeholder="Email address"/>
                    <input onChange={onChange} value={formState.password} type="password" name="password" placeholder="Password"/>
                    <input onChange={onChange} value={formState["repeat-password"]} type="password" name="repeat-password" placeholder="Repeat password"/>
                    <div className="newsletter">
                        <input onChange={newsletterChange} checked={newsletter} type="checkbox" name="newsletter" id="newsletter"/>
                        <label htmlFor="newsletter">Sign me up for braw newsletters!</label>
                    </div>

                    { error ? <p className="error">
                        { error }
                    </p> : '' }

                    { message ? <p className="message">
                        { message }
                    </p> : '' }

                    <input type="submit" value="Register"/>
                    <p className="register" onClick={ () => { setLoginOrRegister('login') } }>
                        Already have an account?
                    </p>
                    <p className="forgotten" onClick={ () => { setLoginOrRegister('forgotten') } }>
                        Forgotten Password
                    </p>
                </form>
            </div>
            {/* <div className="not-on-laptop">
                <StickyLogin back={() => { setLoginOrRegister('login')}} createAccount={null} />
            </div> */}
        </Container>
    )
})
