import React, { useCallback, useEffect, useState } from 'react'
import { AddressListContainer } from '../components/Account-Page/Address/AddressList'
import BasicDetails from '../components/Account-Page/BasicDetails/BasicDetails'
import { OrdersList } from '../components/Account-Page/Orders/OrdersList'
import { PasswordContainer } from '../components/Account-Page/Password/PasswordContainer'
import { PaymentDetailsList } from '../components/Account-Page/PaymentDetails/PaymentDetailsList'
import { SubscriptionsContainer } from '../components/Account-Page/Subscriptions/SubscriptionList'
import Container from '../components/container/container'
import Page from '../components/page/page'
import TopComponent from '../components/top-component/top-component'
import Request from '../helpers/request'

export default function Account() {
    const [self, setSelf] = useState(null)

    const getData = useCallback(() => {
        console.log('Callback happened')
        return Request(true).get('/self').then(e => setSelf(e.data))
    }, [])

    useEffect(() => {
        console.log('use effect happened')
        getData()
    }, [getData])

    return (
        <Page>
            <TopComponent>
                <Container style={{maxWidth: 700, minWidth: 'unset', padding: 20}}>
                    <h1 className="purple-heading text-center" style={{marginBottom: 50}}>My account</h1>

                    {
                        self ? <>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 50}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                                    <h2 className="purple-heading medium text-center">My details</h2>
                                    <BasicDetails self={self} getData={getData} />
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                                    <h2 className="purple-heading medium text-center">My password</h2>
                                    <PasswordContainer />
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                                    <h2 className="purple-heading medium text-center">My address</h2>
                                    <AddressListContainer getData={getData} self={self} />
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                                    <h2 className="purple-heading medium text-center">My orders</h2>
                                    <OrdersList />
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                                    <h2 className="purple-heading medium text-center">My subscriptions</h2>
                                    <SubscriptionsContainer />
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                                    <h2 className="purple-heading medium text-center">My payment details</h2>
                                    <PaymentDetailsList />
                                </div>
                            </div>
                        </> : null
                    }
                </Container>
            </TopComponent>
        </Page>
    )
}



