import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import request from '../../helpers/request';

import './shop-overview-card.scss';

export default function ShopOverviewCard({ vendor: vendorId }) {
  const [vendor, setVendor] = useState({});

  useEffect(() => {
    async function getVendor() {
      setVendor((await request(false).get(`/vendors/${vendorId}`)).data);
    }

    getVendor();
  }, [vendorId]);

  return (
    <div className='shop-overview-card'>
      <div className='shop-overview-card-left'>
        <img
          alt=''
          src={
            vendor.images && vendor.images.length ? vendor.images[0].url : ''
          }
        />
      </div>
      <div className='shop-overview-card-right'>
        <div className='shop-overview-card-right-top'>
          <p>{vendor.name}</p>
          {vendor.category ? <p>{vendor.category.name}</p> : ''}
          {vendor.address ? (
            <p>
              {vendor.address.street}, {vendor.address.locality}
            </p>
          ) : (
            ''
          )}
        </div>
        {/* <div className='shop-overview-card-right-bottom'>
          <p>
            <span className='fas fa-info-circle'></span>&nbsp;More info
          </p>
        </div> */}
      </div>
    </div>
  );
}
