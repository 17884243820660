import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../container/container";
import Slider from "react-slick";
import "./votw.scss";

import vendorMobile from "../../assets/votw/shop-keeper.png";

export default function VendorOfTheWeek() {
  const { t } = useTranslation("common");

  const sliderSettings = React.useMemo(() => {
    return {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      arrows: false,
      centerPadding: "0%",
    };
  }, []);

  return (
    <div className="votw-wrapper">
      <div className="img-wrapper">
        <img
          src={vendorMobile}
          alt=""
          srcSet={`${vendorMobile} 375w`}
          sizes="(max-width: 599px) 375px"
        />
      </div>
      <section className="vendor-of-the-week">
        <Container>
          <div className="content">
            <div className="text">
              <h2>{t("votw.title")}</h2>
              <Slider {...sliderSettings}>
                <div className="votw-text">
                  <p>{t("votw.slide-1")}</p>
                </div>
                <div className="votw-text">
                  <p>{t("votw.slide-1")}</p>
                </div>
                <div className="votw-text">
                  <p>{t("votw.slide-1")}</p>
                </div>
              </Slider>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
