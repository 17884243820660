import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import request from '../../helpers/request'
// import SimpleSelect from 'react-simple-styleable-select'
import './highstreet.scss';
import imgFoodBox from '../../assets/home-hero/foodbox-items.png';
import imgLocaloVan from '../../assets/home-hero/localo-van.png';
import Request from '../../helpers/request';
import EmptyProductModal from '../empty-product-modal/empty-product-modal';
// import { useMemo } from 'react';

export default function HighstreetSelect() {
  const history = useHistory();
  const [emptyModal, setEmptyModal] = useState(false);
  // const [highstreets, setHighstreets] = useState([])
  const [postcode, setPostcode] = useState('');

  const toggleEmptyModal = () => {
    setEmptyModal(!emptyModal);
  };

  // useEffect(() => {
  //     request(false).get(`/highstreets`).then(data => {
  //         setHighstreets(data.data.data)
  //     })
  // }, [])

  // const highstreetOptions = useMemo(() => {
  //     let toSet = []
  //     highstreets.forEach(e => { toSet.push({ label: e.name, value: e._id }) })
  //     return JSON.parse(JSON.stringify(toSet))
  // }, [highstreets])

  // const onVendorSelect = (a, value) => {
  //     // alert(value)
  //     history.push(`/products/${value}/products`)
  // }

  const postcodeRedirect = () => {
    if (!postcode) return;
    Request(false)
      .get(`/highstreets/available?postcode=${postcode}`)
      .then((response) => {
        if (response?.data?.available) {
          history.push(`/products/${postcode}/index`);
        }
        toggleEmptyModal();
      })
      .catch((e) => {
        console.error(e.response);
        toggleEmptyModal();
      });
  };

  return (
    <div className="highstreet-select">
      <div className="offer-banner">
        <i className="money-icon"></i>Welcome to Localo - all prices include
        delivery!
        <img src={imgLocaloVan} alt="Localo : The foodbox market" />
      </div>
      <div className="top">
        <h1>The foodbox market</h1>
        {/* <h2>
                    Personalised for you, from your local shops.
                </h2> */}
      </div>
      <div className="bottom">
        {/* {highstreetOptions && highstreetOptions.length ? <SimpleSelect hasSharpCorners onChange={onVendorSelect} options={highstreetOptions} /> : null} */}
        <div className="foodbox">
          <img src={imgFoodBox} alt="The foodbox market" />
        </div>
        <div className="content">
          <h2>
            Customisable foodboxes from your <strong>local</strong> shops,
            delivered to your door
          </h2>
        </div>
        <div className="form-container">
          <div className="highstreet-select--form">
            <input
              onKeyDown={(_) => {
                if (_.key === 'Enter') {
                  postcodeRedirect();
                }
              }}
              placeholder="Your postcode"
              className="field-postcode"
              type="text"
              onChange={(_) => setPostcode(_.target.value)}
            />
            <span className="postcode-btn" onClick={() => postcodeRedirect()}>
              Find shops
            </span>
          </div>
        </div>
        {/* <p>
                Current areas: Milngavie, Bearsden, Anniesland, Broomhill, Hyndland & Kelvindale. Keep checking back as we will be adding new locations soon!
                </p> */}
      </div>
      <EmptyProductModal
        showModal={emptyModal}
        toggleModal={toggleEmptyModal}
      />
    </div>
  );
}
