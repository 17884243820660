import React from 'react';
import { useEffect } from 'react';
import request from '../../helpers/request';
import ShopProductListItem from '../shop-product-list-item/shop-product-list-item';

import './shop-product-list.scss';

export default function ShopProductList({
  history,
  vendor,
  highstreet,
  showDetailView,
  products,
  setProducts,
  productId
}) {
  
  useEffect(() => {
    async function getProducts() {
      setProducts(
        (await request(false).get(`/products?vendor=${vendor}`)).data.data
      );
    }

    getProducts();
  }, [vendor, setProducts]);

  
  const changed = (value) => {
    history.push(`/products/${highstreet}/shops/${vendor}/${value}`);
    showDetailView(true);
  };

  return (
    <>
      {products.map((product) => {
        return (
          <ShopProductListItem
            key={product._id}
            highstreet={highstreet}
            history={history}
            {...product}
            showDetailView={showDetailView}
            productId={productId}
            handleCardClick={() => changed(product._id) }
          />
        );
      })}
    </>
  );
}
