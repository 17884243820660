import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../container/container';
import Request from '../../helpers/request';
import ProductCustomisationModal from '../product-customisation-modal/product-customisation-modal';
import PriceProduct from '../../helpers/price-product';
import ProgressBar from '../../components/progress-bar/progress-bar';
import { setCost, setDeliverySlots as reduxSetDeliverySlots, setVendor } from './redux/action';
import { getProduct } from './redux/selector';
import './basket.scss';

Modal.setAppElement('#root');

export function ProductQuantity({
  subscriptionType,
  setQuantity,
  setFrequency,
  cartItem
}) {
  const { t } = useTranslation('common');  
  const productBoxs =  useMemo(() => [
    {
      label: t('basket.boxes.1'),
      value: JSON.stringify({ quantity: 1, frequency: 'Once' }),
    },
    {
      label: t('basket.boxes.2'),
      value: JSON.stringify({ quantity: 2, frequency: 'Once' }),
    },
    {
      label: t('basket.boxes.3'),
      value: JSON.stringify({ quantity: 3, frequency: 'Once' }),
    },
    {
      label: t('basket.boxes.4'),
      value: JSON.stringify({ quantity: 4, frequency: 'Once' }),
    },
  ], [t])

  const productWeeks =  useMemo(() => [
    {
      label: `${t('basket.boxes.1')} Weekly`,
      value: JSON.stringify({ quantity: 1, frequency: 'Weekly' }),
    },
    {
      label: `${t('basket.boxes.2')} Weekly`,
      value: JSON.stringify({ quantity: 2, frequency: 'Weekly' }),
    },
    {
      label: `${t('basket.boxes.3')} Weekly`,
      value: JSON.stringify({ quantity: 3, frequency: 'Weekly' }),
    },
    {
      label: `${t('basket.boxes.1')} Monthly`,
      value: JSON.stringify({ quantity: 1, frequency: 'Monthly' }),
    },
    {
      label: `${t('basket.boxes.2')} Monthly`,
      value: JSON.stringify({ quantity: 2, frequency: 'Monthly' }),
    },
    {
      label: `${t('basket.boxes.3')} Monthly`,
      value: JSON.stringify({ quantity: 3, frequency: 'Monthly' }),
    },
  ] ,[t])

  const [pQuantity, setPQuantity] = useState(productBoxs[0]);
  const [selectedOption, setSelectedOption] = useState(pQuantity[0]);

  useEffect(() => {
    if (subscriptionType === 'Once') {
      setSelectedOption(productBoxs[0]);
      setPQuantity(productBoxs);
    } else {
      setSelectedOption(productWeeks[0]);
      setPQuantity(productWeeks);
    }
  }, [subscriptionType,  productWeeks, productBoxs]);

  // back button from basket step 2 to step 1
 useEffect(() => {
  const selected = JSON.stringify({ quantity: cartItem.quantity, frequency: cartItem.frequency });  
  let selectedOpt =  productBoxs.filter(p => p.value === selected)
  if(selectedOpt.length === 0)
   selectedOpt = productWeeks.filter(p => p.value === selected)
  
  setSelectedOption(selectedOpt[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem.frequency, cartItem.quantity])

  return (
    <ReactSelect
      value={selectedOption ? selectedOption : pQuantity[0]}
      onChange={val => {
        const value = JSON.parse(val.value);
        setQuantity(value.quantity);
        setFrequency(value.frequency);
        setSelectedOption(val);
      }}
      options={pQuantity}
      defaultValue={pQuantity[0]}
      className='react-select'
      classNamePrefix='react-select'
      isSearchable={false}
    />
  );
}
export function BasketItem({
  item,
  identifier,
  removeItem,
  updateItem,
  quickDelivery,
  ...props
}) {
  // const { t } = useTranslation("common");
  const [frequency, setFrequency] = React.useState('Once');
  const [quantity, setQuantity] = React.useState(1);
  const [ready, setReady] = React.useState(false);
  const [showCustomisation, setShowCustomisation] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState( item.frequency || 'Once');
  const [currentDay, setCurrentDay] = useState({});

  const dispatch = useDispatch()
  const product = useSelector(state => getProduct(state, item.id))
  const deliverySlots = product[0]?.slots || []
  const price = product[0]?.cost || 0
  const vendor = product[0]?.vendor || null

  const days = useMemo(() => {
    const ret = [];
    Object.keys(deliverySlots).forEach(day => {
      ret.push({
        id: deliverySlots[day].id,
        day: deliverySlots[day].day,
        available: deliverySlots[day].available,
        date: deliverySlots[day].date,
      });
    });
    return ret;
  }, [deliverySlots]);

  useEffect(() => {
    if (!days || !days.length) return;
    let firstDay = days.filter(a => a.available)[0];
    setCurrentDay(firstDay);
  }, [days]);

  useEffect(() => {
    // console.log('Update triggered', identifier, item, frequency, quantity, ready)
    // console.log('item', item, frequency, quantity)   
    if (
      // item.frequency === undefined ||
      // item.quantity === undefined ||
      ready === true
    ) {
      let slots = [];

      if (quantity > 0 && (frequency === 'Monthly' || frequency === 'Once')) {
        slots.push({
          quantity,
          day: null,
          slot: null,
        });
      }

      for (let i = 1; i <= parseInt(quantity); i++) {
        if (frequency !== 'Monthly' && frequency !== 'Once') {
          slots.push({
            day: null,
            slot: null,
          });
        }
      }
      // console.log(slots, item);
      if (
        item.frequency !== frequency ||
        slots.length !== item.slots.length ||
        item.quantity !== parseInt(quantity)
      ) {       
        // if (item.frequency) setFrequency(item.frequency);
        // if (item.quantity) setQuantity(item.quantity);
        updateItem(identifier, {
          ...item,
          frequency: frequency,
          quantity: parseInt(quantity),
          slots: slots,
        });
      }
    } else {
      setFrequency(item.frequency);
      setQuantity(item.quantity);
      setReady(true);      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier, frequency, quantity, ready]);

  const remove = e => {
    e.preventDefault();

    removeItem(identifier);
  };

  useEffect(() => {
    if (subscriptionType === 'Once') {
      setFrequency('Once');
    } else {
      setFrequency('Weekly');
      // setFrequency('Daily');
    }
    setQuantity(1)
    // updateItem(identifier, {
    //   ...item,
    //   quickDelivery: false,
    // });
    // eslint-disable-next-line
  }, [subscriptionType]);

  useEffect(() => {
    setFrequency(item.frequency)
    setQuantity(item.quantity)
    // setSubscriptionType(item.frequency)
  }, [item.frequency, item.quantity])

  useEffect(() => {
    Request(true)
      .get(`/helpers/delivery-slots${quickDelivery ? '?quick=1' : ''}`)
      .then(e => {
        // setDeliverySlots(e.data);
        dispatch(reduxSetDeliverySlots(e.data, item.id))
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickDelivery]);

  useEffect(() => {
    Request(false)
      .get(`/vendors/${item.raw.product.vendor}`)
      .then(_ => 
        dispatch(setVendor(_.data, item.id))
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.raw.product.vendor]);

  useEffect(() => {
    PriceProduct(item.raw.product, item.raw.variant, item.customisations).then(
      e => {
        // setPrice(e.data.total);
        dispatch(setCost(e.data.total, item.id))
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.raw.product, item.raw.variant, item.customisations]);

  return <>
    <div className='item'>
      <div className='shop-name not-on-laptop'>
        {vendor ? vendor.name : null}
      </div>
      <div className='item-left-container'>
        <div className='img-wrapper'>
          {item.raw.product.images && item.raw.product.images[0] ? (
            <img alt='' src={item.raw.product.images[0].url} />
          ) : (
            ''
          )}
        </div>
        <div className='item-text'>
          <div className='shop-name is-for-large'>
            {vendor ? vendor.name : null}
          </div>
          <h2 className='box-name'>{item.raw.product.name}</h2>
          <div className='get-it'>Get it {currentDay.day || ''}</div>
          <div className='size-and-price'>
            <p>{item.raw.variant.title} box</p>
            <p>£{price} each</p>
          </div>
          <div className='customise-box'>
            <p onClick={() => setShowCustomisation(true)}>Customise box</p>
            <button onClick={e => remove(e)} className='remove-btn'>
              <i className='far fa-trash fa-lg'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='item-right-container'>
        {/* <div> */}
        {/* {item.raw.product.quickDelivery ? (
          <div className="quick-large">
            <div className="quick__delivery-btn ">
              <i class="fal fa-stopwatch"></i>
              <div>
                <p>Same Day</p> <p>delivery available</p>
              </div>
            </div>
          </div>
          ) : null} */}
        <div className='select__container'>
          <div className='select-quantity'>
            <ProductQuantity
              subscriptionType={subscriptionType}
              setQuantity={setQuantity}
              setFrequency={setFrequency}
              quantity={quantity}
              frequency={frequency}
              cartItem={item}
            />
            {/* <select
                value={JSON.stringify({quantity,frequency})}
                onChange={(ev) => {const value = JSON.parse(ev.target.value); setQuantity(value.quantity);setFrequency(value.frequency)}}
              >
              <option value={1}>{t("basket.boxes.1")}</option>
              <option value={2}>{t("basket.boxes.2")}</option>
              <option value={3}>{t("basket.boxes.3")}</option>
              <option value={4}>{t("basket.boxes.4")}</option>
              {
                subscriptionType === 'Once' ? <>
                  <option value={JSON.stringify({quantity:1,frequency:'Once'})}>{t("basket.boxes.1")}</option>
                  <option value={JSON.stringify({quantity:2,frequency:'Once'})}>{t("basket.boxes.2")}</option>
                  <option value={JSON.stringify({quantity:3,frequency:'Once'})}>{t("basket.boxes.3")}</option>
                  <option value={JSON.stringify({quantity:4,frequency:'Once'})}>{t("basket.boxes.4")}</option>
                </> : <> */}
            {/* <option value={JSON.stringify({quantity:1,frequency:'Daily'})}>{t("basket.boxes.1")} Daily</option> */}
            {/* <option value={JSON.stringify({quantity:1,frequency:'Weekly'})}>{t("basket.boxes.1")} Weekly</option>
                  <option value={JSON.stringify({quantity:2,frequency:'Weekly'})}>{t("basket.boxes.2")} Weekly</option>
                  <option value={JSON.stringify({quantity:3,frequency:'Weekly'})}>{t("basket.boxes.3")} Weekly</option> */}
            {/* <option value={JSON.stringify({quantity:4,frequency:'Weekly'})}>{t("basket.boxes.4")} Weekly</option> */}
            {/* <option value={JSON.stringify({quantity:1,frequency:'Monthly'})}>{t("basket.boxes.1")} Monthly</option>
                  <option value={JSON.stringify({quantity:2,frequency:'Monthly'})}>{t("basket.boxes.2")} Monthly</option>
                  <option value={JSON.stringify({quantity:3,frequency:'Monthly'})}>{t("basket.boxes.3")} Monthly</option> */}
            {/* <option value={JSON.stringify({quantity:4,frequency:'Monthly'})}>{t("basket.boxes.4")} Monthly</option> */}
            {/* </>
              }
              </select> */}
          </div>
          {/* <div className="select-frequency">
              {!item.quickDelivery ? (
                <select
                  value={frequency}
                  onChange={(ev) => setFrequency(ev.target.value)}
                >
                  <option value="Once">{t("basket.frequency.once")}</option>
                  {
                    subscriptionType === 'Subscription' ? <>
                      <option value="Weekly">{t("basket.frequency.weekly")}</option>
                      <option value="Monthly">{t("basket.frequency.monthly")}</option>
                    </> : null
                  }
                </select>
              ) : null}
            </div> */}
        </div>
        <div className='frequency__radio-btn'>
          <label className='is-for-large'>
            <input
              checked={subscriptionType === 'Subscription' || subscriptionType !== "Once"}
              onChange={e =>
                setSubscriptionType(e.target.checked ? 'Subscription' : 'Once')
              }
              type='checkbox'
              name='frequency'
            />
            <span>
              {subscriptionType === 'Subscription'
                ? "Nice one, you've subscribed"
                : 'Love this? Why not subscribe?'}
            </span>
          </label>
          <label className='not-on-laptop'>
            <input
              checked={subscriptionType === 'Subscription' || subscriptionType !== "Once"}
              onChange={e =>
                setSubscriptionType(e.target.checked ? 'Subscription' : 'Once')
              }
              type='checkbox'
              name='frequency'
            />
            <span>
              {subscriptionType === 'Subscription' ? 'Subscribe' : 'Subscribe'}
            </span>
          </label>
          {/* {
              item.raw.product.quickDelivery ? <>
                <label>
                  <input checked={subscriptionType === 'SameDay'} onClick={() => setSubscriptionType('SameDay')} type="checkbox" name="frequency" />
                  Same Day
                </label>
              </> : null
            } */}
          {/* </div> */}
        </div>
      </div>
    </div>
    <ProductCustomisationModal
      showModal={showCustomisation}
      key={`schedule-item-${identifier}`}
      toggleModal={setShowCustomisation}
      customisation={item.customisations}
      variant={item.raw.variant}
      setCustomisation={c =>
        updateItem(identifier, { ...item, customisations: c })
      }
    />
    </>
}

export default function BasketComponent({
  back,
  next,
  cart,
  removeItem,
  updateItem,
  history,
  ...props
}) {  
  const { t } = useTranslation('common');
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      //setModalIsOpen(true);
    }, 3000);
  }, []);

  return (
    <Container>
      <div className='basket'>
        <div className='basket-header'>
          {cart && cart.length ? <ProgressBar currentStep={1} /> : ''}
          <h1 className='title-back-button'>
            <button onClick={() => history.goBack()} className='back'>
              {t('basket.back')}
            </button>
            <span>
              {cart && cart.length
                ? t('basket.checkout-confirm-products')
                : 'Your basket is empty'}
            </span>
          </h1>
        </div>
        {cart && !cart.length && (
          <div className="text-center">
            <button onClick={() => history.push('/')} className="button-start-shopping">{t('basket.start-shopping')}</button>
          </div>
        )}
        <div className='items'>
          {cart.map((i, k) => {
            return React.Children.toArray(
              <BasketItem
                removeItem={removeItem}
                updateItem={updateItem}
                identifier={k}
                item={i}
                key={`basket-item-${k}`}
              />
            );
          })}
        </div>
      </div>
      <div className='sticky-step-1-bar'>
        <div className='back' onClick={() => history.goBack()}>
          {t('basket.back')}
        </div>
        {cart && cart.length ? (
          <div className='next' onClick={next}>
            {t('basket.next-step')}
          </div>
        ) : (
          ' '
        )}
      </div>
      <Modal
        className='basket-help-modal'
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={t('basket.help-modal')}
        overlayClassName={'basket-help-wrapper'}
      >
        <div>
          <h2>{t('basket.delivery-options.title')}</h2>
          <p className='text-main'>{t('basket.delivery-options.text')}</p>
          <div className='delivery-modal-okay' onClick={closeModal}>
            {t('basket.delivery-options.ok')}
          </div>
        </div>
        <div>
          <h3>{t('basket.delivery-options.one-off.title')}</h3>
          <p>{t('basket.delivery-options.one-off.text')}</p>
          <h3>{t('basket.delivery-options.subscription.title')}</h3>
          <p>{t('basket.delivery-options.subscription.text')}</p>
        </div>
        <div className='delivery-modal-okay' onClick={closeModal}>
          {t('basket.delivery-options.ok')}
        </div>
      </Modal>
    </Container>
  );
}
